import $ from 'jquery';
import 'slick';
import { 
    slickCommonOptions,
} from '../../scripts/config';

/**
 * JumbotronSliderV1 widget configs
 */
const jumbotronSliderV1Semantics = {
    'mainWrapClass': 'jumbotron-slider-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'sliderElementClass': 'jumbotron-slider-v1__slider-init',
    'countSlides': 'data-count-slides',
    'sliderArrowLeftClass': 'js-showroom-slider-prev',
    'sliderArrowRightClass': 'js-showroom-slider-next',
};

/**
 * JumbotronSliderV1 initializer
 */
$(function () {
    JumbotronSliderV1.init();
});

class JumbotronSliderV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${jumbotronSliderV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.jumbotronSliderV1) {
            window.widgets.jumbotronSliderV1 = new JumbotronSliderV1();
        }

        /**
         * Checking other instances
         */
        $(`.${jumbotronSliderV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${jumbotronSliderV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.jumbotronSliderV1.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${jumbotronSliderV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions =
            el.data(
                `${jumbotronSliderV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions
        );

        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${jumbotronSliderV1Semantics.sliderElementClass}`
            ).slick(pluginOptions);
            el.attr(`${jumbotronSliderV1Semantics.isInitializedAttribute}`, true);
                window.widgets.jumbotronSliderV1.pluginInstances[instanceId] = slick;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    reInit(instanceId) {
        JumbotronSliderV1.init();
    }
}