const yaShare2V1Semantics = {
    mainWrapClass: 'ya-share2',
};

function loadYaShareScript(callback) {
    if (window.yaShareScriptLoaded) {
        if (typeof callback === 'function') callback();
        return;
    }

    if (window.yaShareScriptLoading) {
        // Если загрузка уже идет, ждем и проверяем через setInterval
        const checkYaShare = setInterval(() => {
            if (window.yaShareScriptLoaded) {
                clearInterval(checkYaShare);
                if (typeof callback === 'function') callback();
            }
        }, 100);
        return;
    }

    // Флаг: загрузка началась
    window.yaShareScriptLoading = true;

    const script = document.createElement('script');
    script.src = 'https://yastatic.net/share2/share.js';
    script.async = true;
    script.onload = function () {
        // Устанавливаем флаг только после успешной загрузки
        window.yaShareScriptLoaded = true;
        // Сбрасываем флаг загрузки
        window.yaShareScriptLoading = false;
        //console.log("YaShare script loaded");
        if (typeof callback === 'function') callback();
    };
    script.onerror = function () {
        // Если скрипт не загрузился, сбрасываем флаг, чтобы можно было попробовать еще раз
        window.yaShareScriptLoading = false;
        console.error("YaShare script failed to load");
    };
    document.head.appendChild(script);
}

// Функция для переинициализации кнопок
// window.YaShare2 создается автоматически самим скриптом от Яндекса
export default function reinitYaShare() {
    loadYaShareScript(() => {
        if (window.YaShare2) {
            document.querySelectorAll(`.${yaShare2V1Semantics.mainWrapClass}`)
                .forEach(el => window.YaShare2(el));
        }
    });
}

// Загружаем скрипт при загрузке страницы
document.addEventListener('DOMContentLoaded', reinitYaShare);
