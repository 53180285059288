import $ from 'jquery';
import protip from 'protip';

// это тоже самое, что и $(document).ready(function(){ ... });
// в документации: https://www.npmjs.com/package/protip
// но устарело, поэтому юзай эту конструкцию
$(function() {

    // подменяем атрибут data-tooltip-content если есть у элемента
    // в data-pt-title и data-pt-position
    $('.js-tooltip').each(function() {
        let $this = $(this);
        let contentId = $this.attr('data-tooltip-content');

        if (contentId) {
            let tooltipContent = $(contentId).html();
            if (tooltipContent) {
                $this.attr('data-pt-title', tooltipContent);
                $this.attr('data-pt-position', 'top');
            }
        }
    });


    $.protip({
        selector: '.js-tooltip',
    });
});