import $ from 'jquery';
import 'slick';
import { 
    slickCommonOptions,
    globalConfig
} from './../../scripts/config';

/**
 * ProductGalleryV1 widget configs
 */
const productGalleryV1Semantics = {
    'mainWrapClass': 'product-gallery-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'product-gallery-v1__prev',
    'controlNextClass': 'product-gallery-v1__next',
    'sliderWrapClass': 'product-gallery-v1__track',
    'sliderElementClass': 'product-gallery-v1__slider-element',
    'sliderElementWrapClass': 'product-gallery-v1__slider-element-wrap',
    'sliderElementNumberIndexAttribute': 'data-number-index',
    'sliderElementLoadStatusAttribute': 'data-is-loaded',
    'mainWrapSourceElementsUrlAttribute': 'data-source-elements-url',
    'mainWrapSourceListUrlAttribute': 'data-source-list-url',
    'mainWrapListIdAttribute': 'data-list-id',
    'mainWrapTotalCountAttribute': 'data-total-count',
    'categoryItemClass': 'product-gallery-v1__category-item',
    'categoryItemIdAttribute': 'data-id',
    'categoryLinkClass': 'product-gallery-v1__category-link',
    'headTitleSelector': '.product-gallery-v1__head .title-v2',
};

/**
 * ProductGalleryV1 initializer
 */
$(function() {
    ProductGalleryV1.init();
});

class ProductGalleryV1
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${productGalleryV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.productGalleryV1) {
            window.widgets.productGalleryV1 = new ProductGalleryV1();
        }

        /**
         * Checking other instances
         */

        $(`.${productGalleryV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${productGalleryV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.productGalleryV1.registerInstance($(el));
            }
        });

        ProductGalleryV1.reInitChildPlugins();

    }

    /**
     * TODO[justMvp]: внедрить универсальное решение
     * для переинициализации любых дочерних js-плагинов
     * внутри родительских
     * @return void
     */
    static reInitChildPlugins(
        timeout = 1000
    ) {
        setTimeout(() => {
            if (window.widgets.galleryV3) {
                window.widgets.galleryV3.resetInitialized(
                    $(`.${productGalleryV1Semantics.mainWrapClass}`)
                );
                window.widgets.galleryV3.initAll(
                    $(`.${productGalleryV1Semantics.mainWrapClass}`)
                );
            }
        }, timeout);
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let self = this;

        let instanceId = 
            el.attr(`${productGalleryV1Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions = 
            el.data(
                `${productGalleryV1Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};


        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: true,
                mobileFirst: true,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 1420,
                        settings: {
                            centerPadding: '3rem',
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            centerPadding: '2rem',
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 425,
                        settings: {
                            arrows: false,
                            centerPadding: '2rem',
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 0,
                        settings: {
                            arrows: false,
                            // centerPadding: '2rem',
                            slidesToShow: 1
                        }
                    }
                ],
                prevArrow: `.${productGalleryV1Semantics.mainWrapClass}` + 
                    `[${productGalleryV1Semantics.instanceIdAttribute}="` + 
                    el.attr(`${productGalleryV1Semantics.instanceIdAttribute}`) + 
                    `"] .${productGalleryV1Semantics.controlPrevClass}`,
                nextArrow: `.${productGalleryV1Semantics.mainWrapClass}` + 
                    `[${productGalleryV1Semantics.instanceIdAttribute}="` + 
                    el.attr(`${productGalleryV1Semantics.instanceIdAttribute}`) + 
                    `"] .${productGalleryV1Semantics.controlNextClass}`,
            }
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${productGalleryV1Semantics.sliderWrapClass}`
            ).slick(pluginOptions);
            el.attr(`${productGalleryV1Semantics.isInitializedAttribute}`, true);
            window.widgets.productGalleryV1.pluginInstances[instanceId] = slick;

            // добавление прослушивателей
            // для дозагрузки элементов
            self.registerListeners(el);

        } catch(e) {
            el.hide();
            throw e;
        }
    }

    reInit() {
        ProductGalleryV1.init();
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerListeners(el) {
        let self = this;

        let mainWrap = el.closest(
            `.${productGalleryV1Semantics.mainWrapClass}`
        );
        if (!mainWrap.length) {
            console.error(
                `ProductGalleryV1 виджет для элемента [el] ` + 
                `не может прослушивать нужные события, так как wrap-элемент ` + 
                `по селектору [selector] не был обнаружен!`, 
                {
                    el: el,
                    selector: `.${productGalleryV1Semantics.mainWrapClass}`
                }
            );
            return;
        }

        // Перед сменой слайдера (AJAX-дозагрузка элементов)
        el.find(
            `.${productGalleryV1Semantics.sliderWrapClass}`
        ).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                        
            // исключаем другие под-слайдеры (например GalleryV3)
            if (slick.$slider[0].className.indexOf(
                productGalleryV1Semantics.sliderWrapClass
            ) === -1) {
                return;
            }
            // и исключаем слайды с кол-вом элементов менее четырех
            if (slick.$slides.length < 4) {
                return;
            }

            let nextSliderElement = $(slick.$slider[0]).find(
                `.${productGalleryV1Semantics.sliderElementClass}` +
                `[${
                    productGalleryV1Semantics.
                        sliderElementNumberIndexAttribute
                }` + 
                `="${nextSlide}"]`
            );
            if (!nextSliderElement.length) {
                console.error(
                    `ProductGalleryV1 виджет для элемента [el] ` + 
                    `не может загружать другие элементы, так как ` + 
                    `по селектору [selector] не был обнаружен!`, 
                    {
                        el: el,
                        selector: (
                            `.${productGalleryV1Semantics.sliderElementClass}` +
                            `[${
                                productGalleryV1Semantics.
                                    sliderElementNumberIndexAttribute
                            }` + 
                            `="${nextSlide}"]`
                        )
                    }
                );
                return;
            }
            let nextSliderWrapElement = (nextSliderElement.hasClass(
                productGalleryV1Semantics.sliderElementWrapClass
            ))
                // когда nextSliderWrapElement является nextSliderElement
                ? nextSliderElement 
                // для случаев когда slick-плагин добавляет собственную обертку
                : nextSliderElement.closest(
                    `.${productGalleryV1Semantics.sliderElementWrapClass}`
                );
            
            let offset = nextSlide;
            let limit = 4;
            let totalCount = parseInt(mainWrap.attr(
                `${productGalleryV1Semantics.mainWrapTotalCountAttribute}`
            ));
            let maxNumberIndex = (totalCount - 1);

            // offset всегда по индексу следующего элемента (nextSlide),
            // а limit по умолчанию всегда 4 
            // (не больше 4 элементов в слайдере за раз для desktop-версий)
            
            // далее алгоритм может изменить offset или limit по
            // следующим кейсам:
            // 1) Все 4 анализируемых элемента (первый по nextSlide и следующие 3 после него)
            // имеют статус "Загружены" (limit и offset устанавливаются в 0, ничего не загружается)
            // 2) Все 4 анализируемых элемента
            // имеют статус "Не загружено" (limit и offset остаются не тронутыми, загрузка в штатном режиме)
            // 3) n элементов (где n>0) имеют статус "Загружено" С ПЕРВОГО элемента
            // (offset смещается на n элементов влево (offset - n), с проверкой на диапазон [0;maxNumberIndex])
            // 4) n элементов (где n>0) имеют статус "Загружено" НЕ С ПЕРВОГО элемента
            // (limit уменьшается на n (limit - n), с проверкой на диапазон [0;n])

            // true = Загружено / false = Не загружено
            let elementLoadStatuses = [];

            // первый элемент
            if (nextSliderElement.attr(
                `${productGalleryV1Semantics.sliderElementLoadStatusAttribute}`
            ) == 0) {
                elementLoadStatuses.push(false);
            } else if (
                nextSliderElement.attr(
                    `${productGalleryV1Semantics.sliderElementLoadStatusAttribute}`
                ) == 1
            ) {
                elementLoadStatuses.push(true);
            }
            let beforeElement = nextSliderWrapElement;

            // следующие 3 элемента
            for (let i = 1; i <= 3; i++) {
                let wrapElement = beforeElement.next(
                    `.${productGalleryV1Semantics.sliderElementWrapClass}`
                );
                let element = (wrapElement.hasClass(
                    productGalleryV1Semantics.sliderElementClass
                )) 
                    // когда sliderElementClass это тот же элемент что и sliderElementWrapClass
                    ? wrapElement
                    // для случая когда slick-плагин добавляет собственную обертку
                    : wrapElement.find(
                        `.${productGalleryV1Semantics.sliderElementClass}`
                    );
                if (wrapElement.length) {
                    if (
                        element.attr(
                            `${productGalleryV1Semantics.sliderElementLoadStatusAttribute}`
                        ) == 0
                    ) {
                        elementLoadStatuses.push(false);
                    } else if (
                        element.attr(
                            `${productGalleryV1Semantics.sliderElementLoadStatusAttribute}`
                        ) == 1
                    ) {
                        elementLoadStatuses.push(true);
                    }
                }
                beforeElement = wrapElement;
            }

            if (elementLoadStatuses.length < 4) {
                console.error(
                    `ProductGalleryV1 виджет для элемента [el] ` + 
                    `не может загрузить дополнительные элементы так как получил ` + 
                    `неверный набор значений [elementLoadStatuses]`, 
                    {
                        el: el,
                        elementLoadStatuses: elementLoadStatuses,
                    }
                );
                return;
            }

            // далее алгоритм может изменить offset или limit по
            // следующим кейсам:

            // 1) Все 4 анализируемых элемента (первый по nextSlide и следующие 3 после него)
            // имеют статус "Загружены" (limit и offset устанавливаются в 0, ничего не загружается)

            // 2) Все 4 анализируемых элемента
            // имеют статус "Не загружено" (limit и offset остаются не тронутыми, загрузка в штатном режиме)

            // 3) n элементов (где n>0) имеют статус "Загружено" С ПЕРВОГО элемента
            // (offset смещается на n элементов вправо (offset + n), 
            // с проверкой на диапазон [0;maxNumberIndex], limit уменьшается на n (limit - n),
            // с проверкой на диапазон [0;4])

            // 4) n элементов (где n>0) имеют статус "Загружено" НЕ С ПЕРВОГО элемента
            // (limit уменьшается на n (limit - n), с проверкой на диапазон [0;4],
            // offset не изменяется (движение слайдера вправо))

            if (!elementLoadStatuses.includes(false)) {
                // все 4 элемента имеют статус "Загружены"
                limit = 0;
                offset = 0;
            } else if (!elementLoadStatuses.includes(true)) {
                // все 4 элемента имеют статус "Не загружено"
            } else if (elementLoadStatuses[0]) {
                // n элементов (где n>0) имеют статус "Загружено" С ПЕРВОГО элемента
                offset += elementLoadStatuses.filter((element) => element).length;
                limit -= elementLoadStatuses.filter((element) => element).length;
                if (offset > maxNumberIndex) {
                    offset = maxNumberIndex;
                }
            } else if (!elementLoadStatuses[0] && elementLoadStatuses.includes(true)) {
                // n элементов (где n>0) имеют статус "Загружено" НЕ С ПЕРВОГО элемента
                limit -= elementLoadStatuses.filter((element) => element).length;
            }
            
            if (limit <= 0) {
                if (globalConfig.isDebugMode === true) {
                    console.info(
                        `ProductGalleryV1 виджет для элемента [el] ` + 
                        `не требует дополнительной загрузки, исходя из элементов ` + 
                        `[limit] и [offset]`, 
                        {
                            el: el,
                            limit: limit,
                            offset: offset,
                        }
                    );
                }
                return;
            }

            // дозагрузка элементов
            $.ajax({
                url: mainWrap.attr(
                    `${productGalleryV1Semantics.mainWrapSourceElementsUrlAttribute}`
                ),
                method: 'GET',
                // processData: false,
                // contentType: false,
                cache: false,
                data: {
                    listId: mainWrap.attr(
                        `${productGalleryV1Semantics.mainWrapListIdAttribute}`
                    ),
                    limit: limit,
                    offset: offset
                },
                success: function (data) {
                    if (typeof data !== 'object') {
                        console.error(
                            `При дозагрузки элементов в виджете ProductGalleryV1 ` + 
                            `элемента [el] ` +
                            `от сервера пришли невалидные данные! [responseData]`,
                            {
                                el: el,
                                responseData: data
                            }
                        );
                        return;
                    }
                    for (var elementNumberIndex in data) {
                        // заменяем элемент заглушку на html-контент 
                        // дозагруженного элемента
                        $(slick.$slider[0]).find(
                            `.${productGalleryV1Semantics.sliderElementClass}` +
                            `[${
                                productGalleryV1Semantics.
                                    sliderElementNumberIndexAttribute
                            }` + 
                            `="${elementNumberIndex}"]`
                        ).replaceWith(data[elementNumberIndex]);

                        // перезапускаем инициализацию дочерних плагинов
                        // (например GalleryV3)
                        ProductGalleryV1.reInitChildPlugins(100);

                    }
                },
                error: function(data) {
                    console.error(
                        `При дозагрузки элементов в виджете ProductGalleryV1 ` + 
                        `элемента [el] ` +
                        `сервер вернул ошибку! [responseData]`,
                        {
                            el: el,
                            responseData: data
                        }
                    );
                    return;
                }
            });

        });

        // При смене товарной категории
        el.on('click', `.${productGalleryV1Semantics.categoryItemClass}`, function(e) {
            e.preventDefault();
            let status = $(this).hasClass('_active');
            if (status) {
                return;
            }

            // дозагрузка элементов
            $.ajax({
                url: mainWrap.attr(
                    `${productGalleryV1Semantics.mainWrapSourceListUrlAttribute}`
                ),
                method: 'GET',
                // processData: false,
                // contentType: false,
                cache: false,
                data: {
                    listId: mainWrap.attr(
                        `${productGalleryV1Semantics.mainWrapListIdAttribute}`
                    ),
                    categoryId: $(this).attr(productGalleryV1Semantics.categoryItemIdAttribute),
                    title: el.find(`${productGalleryV1Semantics.headTitleSelector}`).text(),
                },
                success: function (data) {
                    if (
                        typeof data !== 'object'
                            ||
                        (
                            typeof data === 'object'
                                &&
                            data['status'] !== true
                        )
                    ) {
                        console.error(
                            `При смене товарной категории в виджете ProductGalleryV1 ` + 
                            `элемента [el] ` +
                            `от сервера пришли невалидные данные! [responseData]`,
                            {
                                el: el,
                                responseData: data
                            }
                        );
                        return;
                    }

                    el.replaceWith(data['content']);
                    self.reInit();
                    ProductGalleryV1.reInitChildPlugins(100);
                },
                error: function(data) {
                    console.error(
                        `При смене товарной категории в виджете ProductGalleryV1 ` + 
                        `элемента [el] ` +
                        `сервер вернул ошибку! [responseData]`,
                        {
                            el: el,
                            responseData: data
                        }
                    );
                    return;
                }
            });
        })
 
        el.on('click', `.${productGalleryV1Semantics.categoryLinkClass}`, function(e) {
            e.preventDefault();
        })

    }
}