import $ from 'jquery';

const regionSelectPopupV1Semantics = {
    'containerSelector': '.info-bar-v1__city_select',
    'popupSelector': '.modal-select-location-v1',
};

$(function () {
    let regionSelectPopupV1 = new RegionSelectPopupV1();
    regionSelectPopupV1.subscribe();
});

class RegionSelectPopupV1 {
    subscribe() {
        let self = this;
        $(document).on(
            'click',
            regionSelectPopupV1Semantics.containerSelector,
            function (e) {
                e.preventDefault();
                $(regionSelectPopupV1Semantics.popupSelector).show();
                e.stopPropagation();
            }
        )

        $(document).on('click', function (e) {
            if (!$(e.target).closest(regionSelectPopupV1Semantics.popupSelector).length) {
                $(regionSelectPopupV1Semantics.popupSelector).hide();
            }
        });

        $(regionSelectPopupV1Semantics.popupSelector).on('click', function (e) {
            // e.stopPropagation();
        });
    }
}
