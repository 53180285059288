import $ from 'jquery';
import 'slick';
import { 
    slickCommonOptions 
} from '../../scripts/config';

/**
 * GalleryV3 widget configs
 */
const galleryV3Semantics = {
    'mainWrapClass': 'gallery-v3',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'sliderElementClass': 'gallery-v3__track',
    'sliderCurrentImage': 'gallery-v3__current-image',
    'dotsElementClass': 'gallery-v3__track-element',
    'dotsActiveClass': 'gallery-v3__track-element_active',
    'overlayElementClass': 'gallery-v3__overlay',
    'pageControlElementClass': 'gallery-v3__page-controller',

};

/**
 * Select initializer
 */

$(function() {

    if ($(`.${galleryV3Semantics.mainWrapClass}`).length === 0) {
        return false;
    }

    window.widgets.galleryV3 = new GalleryV3();

    $(`.${galleryV3Semantics.mainWrapClass}`).each((index, el) => {
        let isInitialized =
            ($(el).attr(`${galleryV3Semantics.isInitializedAttribute}`) === 'true');
        // if (!isInitialized) {
            window.widgets.galleryV3.registerInstance($(el));
        // }
    });

    window.widgets.galleryV3.initAll();
});

class GalleryV3 {
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        let self = this;
        this.pluginInstances = {};
        
        // устанавливается специальное
        // событие для реагирования
        // на запрос переинициализации
        // document.addEventListener("resetSlickGalleryV3", function(e) {
        //     let element = $(e.detail.el);
        //     self.resetInitializedStatus(element);
        // });
    }

    /**
     * Контекст поиска в виде html-элемента
     * @param jQuery context
     */
    initAll(
        context
    ) {
        let self = this;

        let els = null;
        if (context) {
            els = context.find(`.${galleryV3Semantics.mainWrapClass}`);
        } else {
            els = $(`.${galleryV3Semantics.mainWrapClass}`);
        }

        /**
         * Checking other instances
         */
        els.each((index, el) => {
            el = $(el);
            if (!el.length) {
                return;
            }
            let isInitialized =
                (el.attr(`${galleryV3Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                self.registerInstance(el);
                self.registerCustomDots(el);
                self.registerCustomSlickEvent(el);
                self.registerPageControl(el);
            }
        });
    }

    registerPageControl(el) {
        el.on('mouseenter', `.${galleryV3Semantics.overlayElementClass}`, function (event) {
            event.preventDefault();
            let index = $(this).index();
            el.find('.' + galleryV3Semantics.sliderCurrentImage).slick('slickGoTo', index);
        });

        el.on('mouseleave', `.${galleryV3Semantics.pageControlElementClass}`, function (event) {
            event.preventDefault();
            el.find('.' + galleryV3Semantics.sliderCurrentImage).slick('slickGoTo', 0);
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${galleryV3Semantics.instanceIdAttribute}`) || false;
        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions =
            el.attr(
                `${galleryV3Semantics.commonPluginOptionsAttribute}`
            ) || {};


        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: false,
                variableWidth: false,
                mobileFirst: true,
                swipeToSlide: true,
                centerMode: false,
                adaptiveHeight: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                speed: 0,
                focusOnSelect: true,
            }
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            if (
                el.find(
                    `.${galleryV3Semantics.sliderCurrentImage}`
                ).hasClass('slick-initialized')
            ) {
                try {
                    el.find(
                        `.${galleryV3Semantics.sliderCurrentImage}`
                    ).slick('unslick');
                } catch (e) {
                    // no way to check real slick init status
                }
            }
            try {
                let slick = el.find(
                    `.${galleryV3Semantics.sliderCurrentImage}`
                ).slick(pluginOptions);
                el.attr(`${galleryV3Semantics.isInitializedAttribute}`, true);
                window.widgets.galleryV3.pluginInstances[instanceId] = slick;
            } catch (e) {
                // no way to check real slick init status
            }

        } catch (e) {
            // el.hide();
            throw e;
        }
    }

    registerCustomDots(el) {
        el.find('.' + galleryV3Semantics.dotsElementClass).on('click', function (e) {
            let index = $(this).index();
            el.find('.' + galleryV3Semantics.sliderCurrentImage).slick('slickGoTo', index);
        });
    }

    registerCustomSlickEvent(el) {
        el.find('.' + galleryV3Semantics.sliderCurrentImage).on('afterChange', function (event, slick, currentSlide) {
            let customDots = el.find('.' + galleryV3Semantics.dotsElementClass);
            customDots.removeClass(galleryV3Semantics.dotsActiveClass);
            customDots.eq(currentSlide).addClass(galleryV3Semantics.dotsActiveClass);
        });
    }

    reInit(instanceId) {
        window.widgets.galleryV3.pluginInstances[instanceId].slick('unslick').slick('init');
    }

    /**
     * Так как slick клонирует DOM-элементы, то наши isInitialized-статусы
     * не будут правильно работать для "сброса" слайдера (после первичной инициализации
     * они всегда будут установлены в true для всех вновь скопированных элементов)
     * 
     * Поэтому для того, чтобы убедиться, что slick-плагином будут проинициализированы
     * все "клонированные" элементы в других вложенных Slick-виджетах...
     * (например:
     *  - GalleryV3 у каждой карточки ProductCardV2 в ProductGalleryV1
     *  - GalleryV3 у каждого шоурума в ShowroomV2 
     *  - и т.д.
     * )
     * ...нам необходимо добавить возможность
     * повторной реинициализации для каждого найденного элемента GalleryV3 извне
     * (например: ShowroomV2 после своей slick-инициализации сделает повторную
     * инициалиацию GalleryV3 внутри себя)
     * 
     * Данный метод будет отвечать за переинициализацию статусов
     * всех виджетов GalleryV3
     * 
     * @param JQuery context
     */
    resetInitialized(
        context
    ) {
        let self = this;

        let els = null;
        if (context) {
            els = context.find(`.${galleryV3Semantics.mainWrapClass}`);
        } else {
            els = $(`.${galleryV3Semantics.mainWrapClass}`);
        }

        // сбросим все isInitialized-статусы нужных элементов
        els.each((index, el) => {
            el = $(el);
            el.attr(`${galleryV3Semantics.isInitializedAttribute}`, false);
            // уничтожим предыдущие плагины slick
            if (el.hasClass('slick-initialized')) {
                try {
                    el.slick('unslick');
                } catch (e) {
                    // no way to check real slick init status
                }
            }
            let instanceId =
                el.attr(`${galleryV3Semantics.instanceIdAttribute}`) || false;
            // удаляем только соответствующие инстансы
            delete window.widgets.galleryV3.pluginInstances[instanceId];
        });
    }

}