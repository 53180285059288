import $ from 'jquery';
import {PluralFormat} from "../../../../../../ReactApp/Services/Formatters/PluralFormatter";

const productShowMoreV1Semantics = {
    'mainWrapClass': 'product-show-more-v1',
    'elementClass': 'product-show-more-v1__action',
    'actionBtnClass': 'product-show-more-v1__text',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
     // Количество элементов за один запрос
    'batchSizeAttribute': 'data-load-per-time',
    // Атрибут для хранения текущего оффсета
    'offsetAttribute': 'data-offset',
    // все доступное кол-во элементов для показа
    'countElementsAttribute': 'data-count-all-items',
    'loadDataIntoElementClass':'product-grid-v1__page-preloader',
    'preloaderClass': 'page-preloader',
};

/**
 * Select initializer
 */
$(function() {

    window.widgets.productShowMoreV1 = new ProductShowMoreV1();
    window.widgets.productShowMoreV1.reInit()
});

class ProductShowMoreV1
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    initAll() {
        let roots = $(`.${productShowMoreV1Semantics.mainWrapClass}`);

        if (roots.length === 0) {
            return false;
        }

        this.pluginInstances = {}

        /**
         * Checking other instances
         */
        roots.each((index, el) => {
            let isInitialized =
                ($(el).attr(`${productShowMoreV1Semantics.isInitializedAttribute}`) === 'true');

            if (!isInitialized) {
                window.widgets.productShowMoreV1.registerInstance($(el));
                window.widgets.productShowMoreV1.registerListeners($(el));
            }
        });
    }
    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${productShowMoreV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${productShowMoreV1Semantics.mainWrapClass}`);
            el.attr(`${productShowMoreV1Semantics.isInitializedAttribute}`, true);
            window.widgets.productShowMoreV1.pluginInstances[instanceId] = object;
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        el.on('click', `.${productShowMoreV1Semantics.elementClass}`, function (e) {
            e.preventDefault();

            let offset = parseInt(el.attr(productShowMoreV1Semantics.offsetAttribute)) || 0;
            // По умолчанию 20 элементов
            let batchSize = parseInt(el.attr(productShowMoreV1Semantics.batchSizeAttribute)) || 20;
            // Всего элементов
            let totalCount = parseInt(el.attr(productShowMoreV1Semantics.countElementsAttribute)) || 0;

            offset++;
            let params = {
                page: offset,
                'per-page': batchSize,
            };
            $.ajax({
                url: '/catalog-data' + document.location.pathname + document.location.search,
                method: 'GET',
                contentType: 'application/json',
                cache: false,
                data: params,
                beforeSend: function () {
                    $('.' + productShowMoreV1Semantics.preloaderClass).show();
                },
                success: function (data) {
                    el.attr(productShowMoreV1Semantics.offsetAttribute, (offset));

                    let remainingCount = totalCount - offset * batchSize + batchSize;

                    let moreBtn = el.find(`.${productShowMoreV1Semantics.actionBtnClass}`);

                    if(batchSize > remainingCount) {
                       batchSize = remainingCount
                    }
                    
                    if (remainingCount > 0 && moreBtn) {
                        moreBtn.text(
                            `Показать еще ${batchSize} из ${remainingCount} ${PluralFormat(
                                remainingCount, 
                                ['предложение', 'предложения', 'предложений']
                            )}`
                        );
                    } else if (moreBtn) {
                        moreBtn.hide();
                    }

                    $(`.${productShowMoreV1Semantics.loadDataIntoElementClass}`)
                        .before(data)
                    ;
                    $('.' + productShowMoreV1Semantics.preloaderClass).hide();
                }
            });
        });
    }

    reInit() {
        this.initAll();
    }
}

let productShowMoreV1 = new ProductShowMoreV1();

export default productShowMoreV1;