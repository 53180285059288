import $ from 'jquery';

const youTubePreviewV1Semantics = {
    'mainWrapClass': 'you-tube-preview-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'youtubeId':'data-youtube-id',
    'iconClass':'youtube-icon',
};

/**
 * youTubePreviewV1 initializer
 */
$(function () {
    if ($(`.${youTubePreviewV1Semantics.mainWrapClass}`).length === 0) {
        return false;
    }

    let youTubePreviewV1 = new YouTubePreviewV1();

    /**
     * Checking other instances
     */

    $(`.${youTubePreviewV1Semantics.mainWrapClass}`).each((index, el) => {
        let objectElement = $(el);
        let isInitialized =
            (objectElement.attr(`${youTubePreviewV1Semantics.isInitializedAttribute}`) === 'true');
        if (!isInitialized) {
            youTubePreviewV1.registerInstance(objectElement);
            youTubePreviewV1.registerListeners(objectElement);
        }
    });

});

class YouTubePreviewV1
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${youTubePreviewV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        el.show();
        try {
            el.attr(`${youTubePreviewV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = el;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        el.on(
            'click',
            function(e) {
                e.preventDefault();
                let $this = $(this);
                const $slider = $this.closest('.slick-slider');
                const $parent = $this.parent();
                const $icon = $parent.find(`.${youTubePreviewV1Semantics.iconClass}`);
                const youtubeId = $this.attr(youTubePreviewV1Semantics.youtubeId);

                $this.addClass('_load');

                $.ajax({
                    url: '/video-data/'+youtubeId,
                    success: function(data) {
                        if (data.status) {
                            $icon.remove();
                            $this.replaceWith(data.data);
                            if ($slider.length > 0) {
                                $slider.addClass('_youtube');
                            }
                        }
                        $this.removeClass('_load');
                    }
                });
                return false;
            }
        )
    }
}