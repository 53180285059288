import $ from 'jquery';

/**
 * YaMapV1 widget configs
 */
const yaMapV1Semantics = {
    'mainWrapClass': 'ya-map-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'renderMapElementClass': 'ya-map-v1__render-map',
    'dataMapContainer': 'ya-map-v1__data-map',
    'dataMapCoords': 'ya-map-v1__data-map-element',
};

/**
 * YaMapV1 initializer
 */
$(function () {
   YaMapV1.init();
});

class YaMapV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${yaMapV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }
        if (!window || !window.widgets || !window.widgets.yaMapV1) {
            window.widgets.yaMapV1 = new YaMapV1();
        }
        /**
         * Checking other instances
         */
        $(`.${yaMapV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${yaMapV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.yaMapV1.registerInstance($(el));
            }
        });
    }
    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${yaMapV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${yaMapV1Semantics.mainWrapClass}`);
            el.attr(`${yaMapV1Semantics.isInitializedAttribute}`, true);
            window.widgets.yaMapV1.pluginInstances[instanceId] = object;
        } catch (e) {
            throw e;
        }

        let items = [];
        let selected = 0;
        let selectedCoord = 0;

        $(el.find(`.${yaMapV1Semantics.dataMapCoords}`)).each(function () {

            if($(this).data('selected')){
                selected = 1;
                selectedCoord = [$(this).data('latitude'), $(this).data('longitude')];
            }

            items.push({
                id: $(this).data('latitude') + ' ' + $(this).data('longitude'),
                coord: [$(this).data('latitude'), $(this).data('longitude')],
                address: $(this).data('address'),
                phone: $(this).data('phone'),
                workingHours: $(this).data('working-hours'),
                selected: ($(this).data('selected')?1:0),
            });
        });

        let elem = el.find(`.${yaMapV1Semantics.dataMapContainer}`);
        let cityId = elem.data('current-city-id');
        let center = null;
        if($('.contacts-map-data div[data-city="'+cityId+'"]').length > 0) {
          let elem = $('.contacts-map-data div[data-city="'+cityId+'"]');
          center = [elem.data('latitude'), elem.data('longitude')];
        } else {
          center = items[0].coord;
        }
        let zoom = 12;

        if(cityId===1){
            this.center = ['55.750054', '37.622850'];
            zoom = 10;
        }
        if(cityId===2){
            // center = ['59.907654', '30.380753'];
            zoom = 9;
        }
        if(selected){
            center = [selectedCoord[0], selectedCoord[1]];
            zoom = 15;
        }
        // let center = null;

        /* if (elem.length > 0) {
             center = [elem.data('latitude'), elem.data('longitude')];
         } else {*/
        //let center = items[0].coord;
        // }

        // запрос на список городов и центр
        const res = {
            center: center,
            zoom: zoom,
            list: items
        }
        // для оффлайн режима
        let id = el.find(`.${yaMapV1Semantics.renderMapElementClass}`);

        try {
            if (ymaps) {
                ymaps.ready(() => this.init(res, id.attr('id')));
            }
        } catch (e) {
            console.error('ошибка карты', e);
        }
    }

    init(res, id) {
        let myMap = new ymaps.Map(id, {
            center: res.center,
            zoom: +res.zoom,
            controls: ['zoomControl'],
        });
        myMap.behaviors.disable('scrollZoom');

        res.list.forEach(item => {
            let object = new ymaps.Placemark(item.coord, {
                balloonContent: this.getBalloonContent(item),
                // iconCaption:  'шоурум MrSleep',
            }, {
                preset: 'islands#icon',
                iconColor: '#7b75c9',
            });

            //какая то непонятная дичь
            object.events.add('balloonopen', function (e) {
                $('.regions-map-data').val(item.value).trigger('change');
            });

            myMap.geoObjects.add(object);
        })

        /* $('.js-address').on('click', function () {
             const coordArr = $(this).data('coords').split(' ');
             myMap.panTo([+coordArr[0], +coordArr[1]], {flying: 1});
             ps.update();
         });

         $('.js-address-input').on('change', function () {
             const $selected = $(this).find('option:selected');

             const coordArr = $selected.data('coords').split(' ');
             const numArr = [[+coordArr[0], +coordArr[1]]];
             myMap.panTo(numArr, {flying: 1});

             const phone = $selected.data('phone');
             const workingHours = $selected.data('working-hours');
             const address = $selected.data('address');

             $('.js-contact-phone').text(phone);
             $('.js-contact-working-hours').text(workingHours);
             $('.js-contact-address').text(address);
             // changeActiveAddress($selected.data('city'));
         });*/
    }

    getBalloonContent({address, phone, workingHours}) {

        const formatPhone =
            `${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)}-${phone.slice(7, 9)}-${phone.slice(9)}`

        return `
            <div class="map-data__baloon">
                <p class="map-data__baloon-text">
                    <b>${address}</b>
                </p>
                <p class="map-data__baloon-text">
                    <b>Режим работы:</b> ${workingHours}
                </p>
                <p class="map-data__baloon-text">
                    <b>Телефон:</b> <a href="tel:${phone}" class="map-data__phone">${formatPhone}</a>
                </p>
            </div>
            `
    }

    reInit(instanceId) {
        YaMapV1.init();
    }
}