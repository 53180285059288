import $ from 'jquery';
import LIVR from 'livr';
import ErrorCodes from '../../services/validator/ErrorCodes';
import Validator from '../../services/validator/Validator';
import { initPopup } from '../../scripts/initPopup';

/**
 * NOTE: this module use the var `window.attachFiles`
 * which is declared and installed in the file: 
 * `app/frontend/assets/js/scripts/form.js`
 * This is a temporary measure after the introduction of a normal JS architecture, 
 * you need to rewrite the code below and issue a unified standard 
 * for collecting and transferring files in forms via JS
 */

const semantics = {
    'wrapClass': 'cta-v1',
    'formName': 'CtaRequestV1Form',
    'formItemClass': 'form__input',
    'errorWrapClass': 'invalid-feedback',
    'submitButtonClass': 'js-call-order-btn',
    'formClass': 'js-call-form',
};

let validatorRules = {
    [`${semantics.formName}[phone]`]: ['required', 'russian_phone'],
};

const validator = new LIVR.Validator(validatorRules);

$(function() {

    var ctaV1 = new CtaV1();
    ctaV1.subscribe();
    
});

class CtaV1
{
    constructor() {
        this.mainEl = $(`.${semantics.wrapClass}`);
        this.isFormActive = false;
    }

    resetError() {
        this.mainEl.find(`.${semantics.formItemClass} input, .${semantics.formItemClass} textarea`).removeClass('_error');
        this.mainEl.find(`.${semantics.formItemClass} .${semantics.errorWrapClass}`).removeClass('show');
    }

    showError(validator) {
        Object.entries(validator.getErrors()).forEach(([name, errCode]) => {
            const field = this.mainEl.find(`[name="${name}"]`);
            if (field.length) {
                switch (errCode) {
                    case ErrorCodes.REQUIRED:
                        field.addClass('_error');
                    break;
                    default:
                        field
                            .closest(`.${semantics.formItemClass}`)
                            .find(`.${semantics.errorWrapClass}`)
                            .addClass('show')
                            .empty()
                            .html($('<p>', {
                                class: '_alert _alert-error',
                            }).html(Validator.generateError(errCode)));
                    break;
                }
            } 
        });
    }

    subscribe() {

        let self = this;

        $(this.mainEl).on('submit', 'form', function(e) {

            e.preventDefault();
            self.resetError();
            const $form = $(this);
            const formData = new FormData(this);
            const formDataObj = {};
            formData.forEach((value, key) => (formDataObj[key] = value));

            const isValidForm = validator.validate(formDataObj);
            if (isValidForm === false) {
                self.showError(validator);
                return false;
            }

            self.isFormActive = true;
            $form.addClass('_input-shown');
    
            $.ajax({
                url: this.action,
                method: this.method,
                processData: false,
                contentType: false,
                cache: false,
                data: formData,

                success: function (data) {
                    self.mainEl.find(`.${semantics.submitButtonClass}`).text('Заказать звонок');

                    const successPopup = $form.closest('.js-form').find('.js-success-popup');
                    $.magnificPopup.open(initPopup.prototype.getConfig(successPopup));
                },

                error: function(data) {
                    const failPopup = $form.closest('.js-form').find('.js-error-popup');
                    $.magnificPopup.open(initPopup.prototype.getConfig(failPopup));
                }
            });

        });

        $(`.${semantics.submitButtonClass}`).on('click', function(e) {
            e.preventDefault();
            const form = $(this).closest('form');
            if (!form.hasClass('_input-shown')) {
                form.addClass('_input-shown');
                $(this).text('Отправить');
            }
        });

        $('body').on('click', function(e) {
            if (
                $(e.target).closest('.js-call-order-btn, .js-phone-input').length
            ) {
                return true;
            }
    
            if (self.isFormActive === true) {
                self.mainEl.find(`.${semantics.formClass}`).removeClass('_input-shown');
                self.mainEl.find(`.${semantics.submitButtonClass}`).text('Заказать звонок');
                self.isFormActive = false;
            }
        });

    }    
}